import { ColormapSchemaTypeEnum } from '@api/colormap/dist/constants';
import type {
    AttributeAssociationData,
    ContinuousColormapResponse,
    DiscreteColormapResponse,
} from '@api/colormap/dist/enhancedColormapClient';
import {
    usePostAssociationMutation,
    usePostColormapMutation,
} from '@api/colormap/dist/enhancedColormapClient';
import { trackError, trackUserAction } from '@local/metrics/';
import type { ColormapSaveData } from '@local/webviz/dist/components/Properties/Colormaps/ColormapControlDialog/ColormapControlDialog.types';
import { useBaseXyz } from '@local/webviz/dist/context';
import {
    generateContinuousColormapSnapshot,
    generateDiscreteColormapSnapshot,
} from '@local/webviz/dist/context/snapshots/colorMapping';
import { parsePrefixUid } from '@local/webviz/dist/utilities';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

import { UserAction } from 'src/metrics.types';
import { useAppDispatch } from 'src/store/store';
import { updateColormapControl } from 'src/store/visualization/visualizationSlice';

import { useAttributeColormapAssociation } from './useAttributeColormapAssociation';

export function useColormapCreator() {
    const dispatch = useAppDispatch();
    const { setStateFromSnapshot, getEntityState } = useBaseXyz();
    const [postColormap] = usePostColormapMutation();
    const [postAssociation] = usePostAssociationMutation();
    const handleAttributeColormapAssociations = useAttributeColormapAssociation();

    const { evouiEnableColormapsApi } = useFlags();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const createColormap = async ({ colormapData, viewId, attributeKey }: ColormapSaveData) => {
        if (!evouiEnableColormapsApi) {
            return '';
        }
        try {
            dispatch(updateColormapControl({ isLoading: true }));
            const response = await postColormap({
                orgId,
                workspaceId,
                colormapData,
                'api-preview': 'opt-in',
            });

            if (response && 'data' in response && response.data && 'id' in response.data) {
                const { data } = response;
                const { id: colormapId, schema } = data;

                // sync xyz state with new colormap created
                let snapshot = {};
                switch (schema) {
                    case ColormapSchemaTypeEnum.Continuous:
                        snapshot = generateContinuousColormapSnapshot(
                            data as ContinuousColormapResponse,
                            getEntityState,
                        );
                        break;
                    case ColormapSchemaTypeEnum.Discrete:
                        snapshot = generateDiscreteColormapSnapshot(
                            data as DiscreteColormapResponse,
                        );
                        break;
                    default:
                        break;
                }
                setStateFromSnapshot(snapshot);

                const objectId = parsePrefixUid(viewId);
                const associationResponse = await createColormapAssociation(objectId, {
                    colormap_id: colormapId,
                    attribute_id: attributeKey,
                });

                if (!associationResponse) {
                    return '';
                }

                await handleAttributeColormapAssociations(objectId);
                trackUserAction(UserAction.USER_CREATED_COLORMAP);
                return colormapId;
            }
            if ('error' in response && response.error && 'error' in response.error) {
                throw new Error(response.error.error);
            }
            return '';
        } catch (error) {
            trackError(
                `Error creating colormap for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
            return '';
        } finally {
            dispatch(updateColormapControl({ isLoading: false }));
        }
    };

    const createColormapAssociation = async (
        objectId: string,
        attributeAssociationData: AttributeAssociationData,
    ) => {
        if (!evouiEnableColormapsApi) {
            return '';
        }
        try {
            const associationResponse = await postAssociation({
                orgId,
                workspaceId,
                attributeAssociationData,
                'api-preview': 'opt-in',
                objectId,
            });

            if (
                'error' in associationResponse &&
                associationResponse.error &&
                'error' in associationResponse.error
            ) {
                throw new Error(associationResponse.error.error);
            }
            return associationResponse;
        } catch (error) {
            trackError(
                `Error creating colormap association for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
            return '';
        }
    };

    return { createColormap };
}
