import type { RestError } from '@azure/storage-blob';
import { BlockBlobClient } from '@azure/storage-blob';
import type { SerializedError } from '@reduxjs/toolkit';
import type { BaseQueryApi, FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { RETRIES, WAIT_TIME_MS } from 'src/constants';
import {
    FILE_API_ERROR_DELETED,
    FILE_API_ERROR_INITIAL_UPLOAD_FAILED,
    FILE_API_ERROR_WORKSPACE_NOT_FOUND,
} from 'src/strings';
import { UploadStatus } from 'src/types/files';

import { enhancedFileClient } from './enhancedFileMiddleware';
import { fileClient } from './fileClient';
import type {
    GetFileByIdApiResponse,
    GetFileByPathApiResponse,
    UpdateFileByIdApiArg,
    UploadFileResponse,
    UpsertFileByPathApiArg,
} from './GENERATED_fileClientEndpoints';
import { waitForMs } from './utils';

const MIN_BLOCKS = 5;
const MAX_BLOCKS = 50_000;
const DEFAULT_BLOCK_SIZE = 1024 * 512; // 500KB;

interface CustomUpdateFilesByIdApiArgs extends UpdateFileByIdApiArg {
    updatedVersion: File;
    updateFileStatus: (status: UploadStatus, percent: number) => void;
    abortSignal: AbortSignal;
}

interface CustomUpsertFileByPathApiArgs extends UpsertFileByPathApiArg {
    uploadFile: File;
    updateFileStatus: (status: UploadStatus, percent: number) => void;
    abortSignal: AbortSignal;
}

interface SeequentApiError {
    status: number;
    data: {
        status: number;
        title?: string;
        type?: string;
        detail?: string;
        upstream_type?: string;
    };
}

const isSeequentApiError = (
    err: FetchBaseQueryError | SerializedError,
): err is SeequentApiError => {
    if ('status' in err) {
        const data = err.data as object;
        return data !== undefined && 'status' in data && 'title' in data && 'type' in data;
    }
    return false;
};

const errorFromFetchError = (err: FetchBaseQueryError | SerializedError): Error | undefined => {
    if (isSeequentApiError(err)) {
        if (err.status === 410) {
            return new Error(FILE_API_ERROR_DELETED);
        }
        if (err.status === 404 && err.data.upstream_type?.endsWith('/workspace/not-found')) {
            return new Error(FILE_API_ERROR_WORKSPACE_NOT_FOUND);
        }
    }
    return undefined;
};

async function uploadBlobInChunks(
    uploadURL: string,
    file: File,
    refreshUploadUrl: () => Promise<string | null>,
    updateFileStatus: (status: UploadStatus, percent: number) => void,
    abortSignal: AbortSignal,
    defaultBlockSize: number = DEFAULT_BLOCK_SIZE,
) {
    let blockBlobClient = new BlockBlobClient(uploadURL);
    const blockIds: string[] = [];
    const fileSize = file.size;
    let offset = 0;
    let blockIndex = 0;
    let lastOffset = -1;
    let percentCompleted = 0;

    // Calculate preferred block size based on file size.
    const maxBlockSize = fileSize / MIN_BLOCKS;
    const minBlockSize = fileSize / MAX_BLOCKS;
    const blockSize = Math.max(minBlockSize, Math.min(defaultBlockSize, maxBlockSize));

    // Upload the file in chunks of blockSize. Update the progress bar after each chunk.
    /* eslint-disable no-await-in-loop */
    while (offset < fileSize) {
        try {
            percentCompleted = (offset / fileSize) * 100;
            updateFileStatus(UploadStatus.Uploading, percentCompleted);
            const chunkSize = Math.min(blockSize, fileSize - offset);
            const chunk = file.slice(offset, offset + chunkSize);
            const blockId = btoa(`block-${blockIndex.toString().padStart(6, '0')}`);
            blockIds.push(blockId);

            await blockBlobClient.stageBlock(blockId, chunk, chunkSize, { abortSignal });

            offset += chunkSize;
            blockIndex += 1;
        } catch (error: unknown) {
            if (abortSignal.aborted) {
                updateFileStatus(UploadStatus.Cancelled, percentCompleted);
                throw new Error('Upload aborted');
            }

            // Only retry if the error is a 403 Forbidden error. This indicates expired signature.
            if ((error as RestError).statusCode !== 403) {
                console.error('Error uploading blob:', error);
                throw error;
            }

            // If we haven't made any progress, we should stop trying to refresh the URL.
            if (lastOffset === offset) {
                console.error('Error uploading blob:', error);
                throw error;
            }

            // Try to refresh the upload URL and continue uploading.
            lastOffset = offset;
            const newUploadURL = await refreshUploadUrl();
            if (newUploadURL) {
                blockBlobClient = new BlockBlobClient(newUploadURL);
            } else {
                console.error('Error uploading blob:', error);
                throw error;
            }
        }
    }

    // Commit the block list to Azure.
    await blockBlobClient.commitBlockList(blockIds);
}

async function fetchUploadUrl(
    args: CustomUpsertFileByPathApiArgs | CustomUpdateFilesByIdApiArgs,
    queryApi: BaseQueryApi,
    versionID?: string | null,
): Promise<{ result: UploadFileResponse | null; error: FetchBaseQueryError | null }> {
    const { workspaceId, organisationId } = args;
    let response;
    if ('fileId' in args) {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.updateFileById.initiate({
                fileId: args.fileId,
                organisationId,
                workspaceId,
                versionId: versionID,
            }),
        );
    } else {
        response = await queryApi.dispatch(
            enhancedFileClient.endpoints.upsertFileByPath.initiate({
                filePath: encodeURIComponent(args.filePath),
                organisationId,
                workspaceId,
                versionId: versionID,
            }),
        );
    }

    if (response.error) {
        const message =
            'status' in response.error && response.error.status === 410
                ? FILE_API_ERROR_DELETED
                : FILE_API_ERROR_INITIAL_UPLOAD_FAILED;
        const error = new Error(message) as unknown as FetchBaseQueryError;
        return { result: null, error };
    }

    return { result: response.data, error: null };
}

const uploadWithPolling = async (
    args: CustomUpsertFileByPathApiArgs | CustomUpdateFilesByIdApiArgs,
    queryApi: BaseQueryApi,
) => {
    const { workspaceId, organisationId, abortSignal } = args;
    const { result: uploadResult, error: errorResult } = await fetchUploadUrl(args, queryApi);

    if (!uploadResult) {
        return { error: errorResult as unknown as FetchBaseQueryError };
    }
    const { file_id: fileId, upload, version_id: versionId } = uploadResult;

    // Define a function to refresh the upload URL for the given version.
    const refreshUploadUrl = async () => {
        const { result: refreshResult, error: refreshError } = await fetchUploadUrl(
            args,
            queryApi,
            versionId,
        );
        if (!refreshResult) {
            console.error('Failed to refresh the upload URL:', refreshError);
            return null;
        }
        return refreshResult.upload;
    };

    // Upload the file to blob storage in chunks.
    const fetchBody = 'fileId' in args ? args.updatedVersion : args.uploadFile;
    try {
        await uploadBlobInChunks(
            upload,
            fetchBody,
            refreshUploadUrl,
            args.updateFileStatus,
            abortSignal,
        );
    } catch (error: unknown) {
        return { error: error as FetchBaseQueryError };
    }

    // We need to wait for the backend to validate that the file has been uploaded to Azure.
    // Since the chunks have been uploaded, we can no longer cancel the upload (immutable status).
    /* eslint-disable no-await-in-loop */
    let fetchCount = 0;
    args.updateFileStatus(UploadStatus.Immutable, 99);

    while (fetchCount < RETRIES) {
        fetchCount += 1;

        await waitForMs(WAIT_TIME_MS);

        const {
            data: newFile,
            isError,
            error: fetchError,
        } = await queryApi.dispatch(
            enhancedFileClient.endpoints.getFileById.initiate(
                {
                    organisationId,
                    workspaceId,
                    fileId,
                    versionId,
                    includeVersions: true,
                },
                { forceRefetch: true, subscribe: false },
            ),
        );

        if (isError) {
            const newError = errorFromFetchError(fetchError);
            if (newError) {
                return { error: newError as unknown as FetchBaseQueryError };
            }
        }

        // When the new file version is available we can invalidate the "get file by ID" cache for
        // this file, triggering a re-fetch (which shows the new version), then return the new file
        if (newFile) {
            return { data: newFile };
        }
    }

    const error = new Error(
        `File upload failed after ${(RETRIES * WAIT_TIME_MS) / 1000} seconds`,
    ) as unknown as FetchBaseQueryError;
    return { error };
};

export const injectedFileClient = fileClient.injectEndpoints({
    endpoints: (build) => ({
        customUploadFileById: build.mutation<GetFileByIdApiResponse, CustomUpdateFilesByIdApiArgs>({
            /**
             * Upsert a file by id. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
        customUpsertFileByPath: build.mutation<
            GetFileByPathApiResponse,
            CustomUpsertFileByPathApiArgs
        >({
            /**
             * Upsert a file by path. Will register the new file with the File API, upload it to Azure blob storage,
             * poll until it's finished uploading, then return the new file at the end.
             */
            queryFn: uploadWithPolling,
            invalidatesTags: (file) => ['File', { type: 'File', id: file?.file_id }],
        }),
    }),
});

export const { useCustomUploadFileByIdMutation, useCustomUpsertFileByPathMutation } =
    injectedFileClient;
