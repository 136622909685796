import { makeStyles } from 'tss-react/mui';

const GROUP_DEFAULT = '#2e3034';
const GROUP_HOVER = '#4a4c4f';
const OBJECT_DEFAULT = '#393b3f';
const OBJECT_HOVER = '#4a4c4f';
const OBJECT_SELECTED = '#3f4144';
const PANEL_BACKGROUND = '#282a2e';
const VISIBILITY_ICON_DISABLED = '#8DD5E7';
const VISIBILITY_ICON_ENABLED = '#e3f5f9';

export const useStyles = makeStyles()(() => ({
    base: {
        zIndex: 10,
        color: 'white',
        padding: '12px',
        cursor: 'default',
        minWidth: '455px',
        maxWidth: '455px',
        maxHeight: '855px',
        borderRadius: '8px',
        pointerEvents: 'auto',
        backgroundColor: PANEL_BACKGROUND,
        boxShadow: `rgba(0, 0, 0, 0.24) 0px 3px 8px`,
    },
    objectContainer: {
        borderRadius: '8px',
        boxShadow: `0px 5px 5px 0px #00000033,
                    0px 3px 14px 0px #0000001F,
                    0px 8px 10px 0px #00000024,
                    0px 1px 1px 0px #FFFFFF26 inset`,
    },
    objectRow: {
        width: '100%',
        height: '52px',
        padding: '6px',
        paddingLeft: '16px',
        backgroundColor: OBJECT_DEFAULT,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: OBJECT_HOVER,
        },
    },
    objectRowSelected: {
        backgroundColor: OBJECT_SELECTED,
    },
    showMoreRow: {
        width: '100%',
        padding: '6px',
        cursor: 'pointer',
        backgroundColor: OBJECT_DEFAULT,
        '&:hover': {
            backgroundColor: OBJECT_HOVER,
        },
    },
    objectIcon: {
        height: '14px',
        maxHeight: '14px',
        width: '14px',
        maxWidth: '14px',
    },
    accordion: {
        color: 'white',
        backgroundColor: GROUP_DEFAULT,
    },
    accordionSummary: {
        padding: 0,
        paddingLeft: '16px',
        height: '48px',
        '&:hover': {
            backgroundColor: GROUP_HOVER,
        },
    },
    accordionSummaryExpanded: {
        backgroundColor: GROUP_HOVER,
    },
    accordionDetails: {
        padding: 0,
        backgroundColor: OBJECT_DEFAULT,
        boxShadow:
            '0px 4px 8px 0px rgba(0, 0, 0, 0.13) inset, 0px 1px 2px 0px rgba(29, 31, 35, 0.13) inset',
    },
    spacer: {
        width: '32px',
    },
    visibilityIcon: {
        height: '18px',
        maxHeight: '18px',
        width: '18px',
        maxWidth: '18px',
        color: VISIBILITY_ICON_DISABLED,
    },
    visibilityIconEnabled: {
        color: VISIBILITY_ICON_ENABLED,
    },
    groupDivider: {
        height: '20px',
        borderColor: '#494c54',
    },
    rowDivider: {
        height: '20px',
        borderColor: '#282a2e',
    },
    textDivider: {
        height: '80%',
        borderColor: 'white',
    },
    itemDivider: {
        borderColor: '#2e3034',
    },
    iconButton: {
        padding: '0',
    },
    showPanelIcon: {
        color: 'white',
    },
    chevronButton: {
        paddingBottom: '14px',
    },
    resultsTitleText: {
        color: '#F2F2F2',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textTransform: 'uppercase',
        padding: '0px 0px 8px 16px',
    },
    resultCountText: {
        color: '#EBEBEB',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '14px',
        padding: '0px 0px 8px 16px',
    },
    groupTitleText: {
        color: '#FAFAFA',
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '14px',
    },
    rowTitleText: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '15px',
    },
    rowSubtitleText: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '15px',
    },
    resultsTitleRow: {
        height: '30px',
        marginTop: '12px',
        marginBottom: '0px',
        transition: 'margin 0.3s',
    },
    resultsTitleRowExpanded: {
        marginBottom: '12px',
    },
    collapseContainer: {
        overflowY: 'auto',
        maxHeight: 'calc(80vh - 168px)',
        borderRadius: '8px',
        boxShadow:
            '0px 1px 1px 0px rgba(255, 255, 255, 0.15) inset, 0px 8px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 14px 0px rgba(0, 0, 0, 0.12), 0px 5px 5px 0px rgba(0, 0, 0, 0.20)',
    },
}));

export const useToolTipStyles = makeStyles()(() => ({
    arrow: {
        color: '#d0d0d0',
    },
    tooltip: {
        maxWidth: '100%',
        fontSize: 16,
    },
}));
