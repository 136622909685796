/* eslint-disable no-param-reassign */

import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import type { CesiumWidget } from '@cesium/engine';
import { ScreenSpaceEventType } from '@cesium/engine';
import { useRef } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';

import { useSearchInteractionContext } from '../../../../../contexts/SearchInteractionContext';
import { useBoundingBox } from '../../Hooks/useBoundingBox';
import { useRemoveRectangleGeometries } from '../../Hooks/useRemoveRectangleGeometries';
import { useStyles } from '../Clustering.styles';
import { ObjectIcon } from '../ObjectIcon';

interface ObjectIconGroupProps {
    objects: OrgListedObject[];
    activeObject: OrgListedObject | null;
    cesiumWidget: CesiumWidget;
    flyToInProgress: React.MutableRefObject<boolean>;
}

export const ObjectIconGroup = ({
    objects,
    activeObject,
    cesiumWidget,
    flyToInProgress,
}: ObjectIconGroupProps) => {
    const { classes } = useStyles();
    const { hoveredObject } = useSearchInteractionContext();
    const { setActiveObject } = useDiscoverContext();

    const boundingBox = useBoundingBox(cesiumWidget, flyToInProgress);
    const boundingBoxCalled = useRef(false);

    const removeRectangleGeometries = useRemoveRectangleGeometries(cesiumWidget);

    const handleOnClick = (object: any) => {
        if (!object) return;
        setActiveObject(object!.object_id);
        if (!boundingBoxCalled.current) boundingBox(object);
        boundingBoxCalled.current = true;
    };

    cesiumWidget.screenSpaceEventHandler.setInputAction((click: any) => {
        const pickedObject = cesiumWidget.scene.pick(click.position);
        if (!pickedObject) {
            removeRectangleGeometries();
        }
    }, ScreenSpaceEventType.LEFT_CLICK);

    return (
        <div className={classes.groupClusterBase}>
            {objects.map((object) => (
                <ObjectIcon
                    key={object.object_id}
                    schema={object.schema}
                    objectID={object.object_id}
                    hovered={object.object_id === hoveredObject}
                    onClick={() => handleOnClick(object)}
                    active={object.object_id === activeObject?.object_id}
                    name={object.name.substring(0, object.name.length - 5)}
                />
            ))}
        </div>
    );
};
