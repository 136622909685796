import { trackError } from '@local/metrics';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import type { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useGetFileByIdQuery } from 'src/apiClients/GENERATED_fileClientEndpoints';
import { BaseSidebar } from 'src/components/sidebar/BaseSidebar';
import { useWorkspaceContext } from 'src/contexts/WorkspaceContext';
import { GET_FILE_FAILED, GET_FILE_FAILED_GONE } from 'src/strings';

import { Empty } from './Empty';
import { Properties } from './Properties';
import { VersionHistory } from './VersionHistory';

interface FileSideBarProps {
    selectedFile?: string;
}

export const Sidebar = ({ selectedFile }: FileSideBarProps) => {
    const params = useParams();
    const [reported, setReported] = useState(false);
    const { workspaceId } = useWorkspaceContext();

    const {
        data: file,
        isFetching,
        isError,
        error,
    } = useGetFileByIdQuery(
        {
            organisationId: getOrgUuidFromParams(params),
            workspaceId,
            fileId: selectedFile ?? '',
            includeVersions: true,
        },
        { refetchOnMountOrArgChange: true },
    );

    useEffect(() => {
        // We only want to know about this once per page load, otherwise we'll flood the metrics because of re-renders
        if (reported) {
            return;
        }
        if (error && 'status' in error && error.status === 410) {
            trackError('File has been deleted in the background', '', {
                workspaceId,
                fileId: selectedFile,
                context: 'loading-sidebar',
            });
            setReported(true);
        }
    }, [error, reported, selectedFile]);

    if (isError) {
        const message =
            (error as FetchBaseQueryError).status === 410 ? GET_FILE_FAILED_GONE : GET_FILE_FAILED;
        return (
            <BaseSidebar
                isFetching={false}
                properties={<Empty error={message} />}
                versionHistory={null}
            />
        );
    }

    if (!selectedFile) {
        return <BaseSidebar isFetching={false} properties={<Empty />} versionHistory={null} />;
    }

    return (
        <BaseSidebar
            isFetching={isFetching && (!file || file?.file_id !== selectedFile)}
            properties={<Properties file={file} />}
            versionHistory={<VersionHistory file={file} />}
        />
    );
};
